import React, { useState, useEffect, useContext, createContext } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';

const AuthenticationContext = createContext();

export const useAuthenticationContext = () => {
  return useContext(AuthenticationContext);
};

const AuthenticationProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAccountNotActivatedOnLogin, setIsAccountNotActivatedOnLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [successfulSignUp, setSuccessfulSignUp] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [hasCustomPlan, setHasCustomPlan] = useState(false);
  const [customPlan, setCustomPlan] = useState({});

  const [message, setMessage] = useState('');
  const [errorMessageExists, setErrorMessageExists] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [redirectedFrom403, setRedirectedFrom403] = useState(false);
  const [didMagicLoginFail, setDidMagicLoginFail] = useState(false);
  const [didMagicLoginFailInvalidOrExpiredToken, setDidMagicLoginFailInvalidOrExpiredToken] = useState(false);

  const [redirectedPlan, setRedirectedPlan] = useState('');

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirected = searchParams.get('redirected_from_403');

    redirected && setRedirectedFrom403(redirected === 'true');
  }, [location.search]);

  // useEffect(() => {
  //   if (window.location.pathname === '/') {
  //     // window.location.href = '/home.html';
  //     window.location.href = '/landing-page';
  //   }
  // }, []);

  const manageUserDataAfterAuth = (data) => {
    setEmail(data.email);
    setFullName(data.fullName);
    setIsAdmin(data.isAdmin);
    setAccessToken(data.token);
    setIsAuthenticated(true);
    setUserSubscriptions(data.subscriptionsFromStripe);
    setHasCustomPlan(data.hasCustomPlan);
    setCustomPlan(data.customPlan);
  };

  const accessTokenFromCookies = localStorage.getItem('token');

  const whoAmI = async (token) => {
    try {
      const { data } = await axios.get('/api/users/who-am-i', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      data.token = token;
      manageUserDataAfterAuth(data);
    } catch (error) {
      logout();
      if (error.response.status === 401) {
        toast.error(`Your session has expired.\n Please log in.`);
      }
    }
    setIsAuthLoading(false);
  };

  const magicLinkLogin = async (tokenId) => {
    try {
      const { data, status } = await axios.get(`/api/users/magic-link-login/${tokenId}`);
      manageUserDataAfterAuth(data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401 || error?.response?.data?.message === 'Invalid token.') {
        setDidMagicLoginFailInvalidOrExpiredToken(true);
      } else {
        setDidMagicLoginFail(true);
      }
    }
  };

  const login = async (email, password) => {
    const headers = {
      'Content-Type': 'application/json',
    };
    setIsLoginLoading(true);

    try {
      const { data } = await axios.post(
        `api/users/login`,
        {
          email,
          password,
        },
        headers
      );
      manageUserDataAfterAuth(data);
      localStorage.setItem('token', data.token);
    } catch (error) {
      console.error(error.response.status, error.response.data.message);
      if (error.response.data.message.includes('not activated')) {
        setIsAccountNotActivatedOnLogin(true);
        setIsLoginLoading(false);
        return;
      }
      setErrorMessageExists(true);
      setMessage(error.response.data.message);
      setTimeout(() => {
        setErrorMessageExists(false);
        setMessage('');
      }, 5000);
      if (error.response.status === 403) console.log('Cannot login');
    }
    setIsLoginLoading(false);
  };

  const register = async (fullName, email, password) => {
    console.log('Trying to register');
    const headers = {
      'Content-Type': 'application/json',
    };
    setIsRegisterLoading(true);

    try {
      const { data } = await axios.post(
        `/api/users/register`,
        {
          fullName,
          email,
          password,
          redirectedPlan,
        },
        headers
      );
      setFullName(fullName);
      setSuccessfulSignUp(true);
    } catch (error) {
      setErrorMessageExists(true);
      setMessage(error?.response?.data?.message || error?.message || 'Unknown Error');
      setIsRegisterLoading(false);
      setTimeout(() => {
        setErrorMessageExists(false);
        setMessage('');
      }, 5000);
      console.error(error?.response?.status, error?.response?.data?.message);
      if (error.response.status === 403) console.log('Cannot register');
    }
    setIsRegisterLoading(false);
  };

  useEffect(() => {
    if (accessTokenFromCookies) {
      if (window.location.pathname === '/logout') {
        logout();
        setIsAuthLoading(false);
      } else {
        whoAmI(accessTokenFromCookies);
      }
    } else {
      setIsAuthLoading(false);
    }
  }, []);

  const saveInfoAtLocalStorage = (token) => {
    localStorage.setItem('token', token);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setIsAdmin(false);
    setAccessToken('');
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  axios.defaults.baseURL = process.env.REACT_APP_PORTAL_BACKEND_DOMAIN;
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  return (
    <AuthenticationContext.Provider
      value={{
        whoAmI,
        login,
        register,
        logout,
        isAuthenticated,
        isAdmin,
        accessToken,
        config,
        email,
        setEmail,
        fullName,
        setFullName,
        hasCustomPlan,
        customPlan,
        isAccountNotActivatedOnLogin,
        setIsAccountNotActivatedOnLogin,
        successfulSignUp,
        setSuccessfulSignUp,
        userSubscriptions,
        message,
        setMessage,
        errorMessageExists,
        setErrorMessageExists,
        isLoginLoading,
        isRegisterLoading,
        redirectedFrom403,
        setRedirectedFrom403,
        saveInfoAtLocalStorage,
        didMagicLoginFail,
        didMagicLoginFailInvalidOrExpiredToken,
        magicLinkLogin,
        redirectedPlan,
        setRedirectedPlan,
      }}
    >
      {isAuthLoading ? <CircularProgress /> : children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
